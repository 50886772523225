import React, { useState } from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldUpload,
  FormInput,
  Heading,
} from '../../snippets';
import { convertToHTML } from 'draft-convert';
import { toast } from 'react-toastify';

export default function TeamRegisterLayers3({
  heading = 'Now tell us a little about your company...',
  onSetDataImage = () => {},
  setDataImageErrorStatus = () => {},
  dataImg = {},
  forms: {
    errors = {},
    register = null,
    loading = false,
    clearErrors = () => {},
    handleSubmit = () => {},
    watchFields = {},
    getValues = () => {},
  },
}) {
  const [bio, setBio] = useState(null);
  const [description, setDescription] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const maxLength = 1000;

  const submitForm = () => {
    setSubmitted(true);

    if (!dataImg.filepath) {
      setDataImageErrorStatus(true);
    }

    if (
      bio?.getCurrentContent().getPlainText().length > maxLength ||
      description?.getCurrentContent().getPlainText().length > maxLength
    ) {
      return toast.error(
        `Bio and Description must be less than ${maxLength} characters`,
        {
          toastId: 'maxLength',
        }
      );
    }

    handleSubmit({
      ...getValues(),
      bio: convertToHTML(bio?.getCurrentContent()),
      description: convertToHTML(description?.getCurrentContent()),
    });
  };

  return (
    <div>
      <Heading as="h3" className="text-center">
        {heading}
      </Heading>
      <FormBlock className={'spacer-t'}>
        <FormBlockItem className="relative" label={'Add Your Company Logo'}>
          <FormFieldUpload
            noOutline
            square
            center
            setImg={onSetDataImage}
            error={dataImg?.error ? 'Please add company logo' : null}
            showWarnError
            onError={setDataImageErrorStatus}
            maxFileSize={8388608}
          ></FormFieldUpload>
        </FormBlockItem>
        <FormBlockItem label={'Add Profile Bio'}>
          <FormInput
            isRequired
            type="wysiwyg"
            name="bio"
            error={
              submitted && (!bio || bio?.length === 0)
                ? `Please add your company profile bio`
                : null
            }
            showErrorText
            maxLength={maxLength}
            clearErrors={clearErrors}
            setValue={setBio}
            fieldClassName={`${
              submitted && (!bio || bio?.length === 0) ? 'error-state ' : ''
            }`}
          ></FormInput>
        </FormBlockItem>
        <FormBlockItem label={'What Sets You Apart'}>
          <FormInput
            isRequired
            type="wysiwyg"
            name="description"
            error={
              submitted && (!description || description?.length === 0)
                ? `Please add a description`
                : null
            }
            showErrorText
            maxLength={maxLength}
            clearErrors={clearErrors}
            setValue={setDescription}
            fieldClassName={`${
              submitted && (!description || description?.length === 0)
                ? 'error-state '
                : ''
            }`}
          ></FormInput>
        </FormBlockItem>

        {/* <FormBlockItem label={'Industry'}>
          {loadingIndustries && <span>Loading...</span>}
          <FormInput
            type="select"
            clearErrors={clearErrors}
            options={arrToDropdownSelectionV2(industries) ?? []}
            selectSetting={{
              onSelect: (value) => {
                setSelectedIndustry(value);
              },
            }}
          ></FormInput>
        </FormBlockItem>
        <FormBlockItem
          label={
            'Are you happy for us to use your logo across our marketing channels?'
          }
        >
          <FormInput
            type="select"
            register={register}
            name="can_use_logo"
            isRequired
            options={[
              {
                value: '',
                label: 'Please Select',
              },
              {
                value: '1',
                label: 'Yes',
              },
              {
                value: '0',
                label: 'No',
              },
            ]}
            error={errors.can_use_logo ? `You must choose one` : null}
          ></FormInput>
        </FormBlockItem> */}

        <FormCTA spaceT extraSpace>
          <Button
            type="submit"
            className="w-full"
            size="xxl"
            disabled={loading}
            onClick={submitForm}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
        </FormCTA>
      </FormBlock>
    </div>
  );
}
