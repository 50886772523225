import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  BackPanel,
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
} from '../../snippets';
import { resetPassword } from '../../../api/auth';
import useGroups from '../../hooks/useGroups';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { formatValidationErrors } from '../../../utils/helpers';
import { LINK_GROUP } from '../../../utils/links';

export default function ResetPasswordPage() {
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const { groupname } = useGroups();
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email').replace(/\s+/g, '+').toLowerCase();
  const token = query.get('token');

  const resetPasswordRequest = async (data) => {
    toast.dismiss();
    setLoading(true);
    const response = await resetPassword({
      ...data,
      token,
      email,
    });
    if (response.data && response.data.errors) {
      setLoading(false);
      toast.error(
        'Your password reset token has expired. Please trigger another request to get a new link.'
      );
    } else {
      reset();
      setLoading(false);
      let url = LINK_GROUP.AGENCY;
      if (
        [
          LINK_GROUP.CANDIDATE,
          LINK_GROUP.FREELANCER,
          LINK_GROUP.CONSULTANT,
        ].includes(groupname)
      ) {
        url = LINK_GROUP.CANDIDATE;
      } else if (
        [LINK_GROUP.EMPLOYER, LINK_GROUP.COMPANY].includes(groupname)
      ) {
        url = LINK_GROUP.COMPANY;
      }
      toast.success(response.message);
      return history.push(`/${url}/login`);
    }
  };

  return (
    <DefaultLayout>
      <BackPanel isSmall>
        <Heading as="h1">Set your new password</Heading>
        <form onSubmit={handleSubmit(resetPasswordRequest)}>
          <FormBlock className={'space-t'}>
            <FormBlockItem label={'New Password'}>
              <FormInput
                register={register}
                type="password"
                name="password"
                isRequired
                error={errors.password ? 'Please enter a password' : null}
                clearErrors={clearErrors}
              />
            </FormBlockItem>

            <FormBlockItem label={'Confirm New Password'}>
              <FormInput
                register={register}
                type="password"
                name="password_confirmation"
                isRequired
                error={
                  errors.password_confirmation
                    ? 'Password confirmation does not match'
                    : null
                }
                clearErrors={clearErrors}
              />
            </FormBlockItem>

            <FormCTA className={'space-t'}>
              <Button
                className="app-btn-submit"
                type="submit"
                disabled={loading}
                size="xl"
              >
                {loading ? 'Loading...' : 'Reset Password'}
              </Button>
            </FormCTA>
          </FormBlock>
        </form>
      </BackPanel>
    </DefaultLayout>
  );
}
