import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  BackPanel,
  Heading,
  Button,
  FormContent,
  FormBlock,
  FormBlockItem,
  FormInput,
  FormCTA,
} from '../../snippets';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_CASE_STUDY,
  DELETE_CASE_STUDY,
  UPDATE_CASE_STUDY,
} from '../../../graphql';
import { LINK_GROUP } from '../../../utils/links';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import UploadField from '../../../components/UploadFieldV2';
import EditUpload from '../../../components/EditUpload';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { convertToHTML } from 'draft-convert';

const EditCaseSudy = () => {
  const { register, handleSubmit, errors, watch, setValue, clearErrors } =
    useForm();
  const [submitted, setSubmitted] = useState(false);
  const [thumbImage, setThumbImage] = useState('');
  const [description, setDescription] = useState('');
  const [originalContent, setOriginalContent] = useState([]);
  const [updatedContent, setUpdatedContent] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const { loading, data: { caseStudy = {} } = {} } = useQuery(GET_CASE_STUDY, {
    fetchPolicy: 'network-only',
    variables: { id },
    onCompleted: ({ caseStudy: { description = '' } = {} }) => {
      setDescription(description);
    },
  });

  const [updateCaseStudy, { loading: updateLoading }] =
    useMutation(UPDATE_CASE_STUDY);

  const watchFields = watch(['video_url', 'description']);
  const [deleteCaseStudy, { loading: deleteLoading }] =
    useMutation(DELETE_CASE_STUDY);

  const notify = (text) => toast.error(text, { autoClose: 3000 });

  const handleDeleteCaseStudy = () => {
    deleteCaseStudy({
      variables: {
        id,
      },
      onCompleted: () => {
        toast.success('Your Case Study was deleted');
        history.push({
          pathname: `/${LINK_GROUP.AGENCY}/case-studies`,
        });
      },
      onError: (err) => {
        notify(err?.message);
      },
    });
  };

  useEffect(() => {
    if (!loading && caseStudy) {
      const { thumbnail, content } = caseStudy;

      thumbnail && !thumbImage && setThumbImage(thumbnail);

      if (content?.length) {
        content.map((item) => {
          setUpdatedContent((prev) => [...prev, null]);
          if (item.layout === 'image') {
            setOriginalContent((prev) => [...prev, item.attributes.image]);
          } else {
            setOriginalContent((prev) => [...prev, item.attributes.url]);
          }
          return item;
        });
      }
    }
  }, [caseStudy, loading]);

  const handleEditCaseStudy = (data) => {
    let input;

    if (typeof thumbImage === 'string') {
      input = {
        ...data,
      };
    } else {
      input = {
        ...data,
        thumbnail: thumbImage,
      };
    }
    updateCaseStudy({
      variables: {
        id: id,
        input: {
          ...input,
          description: convertToHTML(description?.getCurrentContent()),
          originalContent: originalContent,
          content: updatedContent,
        },
      },
      onCompleted: () => {
        setOriginalContent([]);
        setUpdatedContent([]);
        toast.success('Case Study updated successfully');
        history.push({
          pathname: `/${LINK_GROUP.AGENCY}/case-studies`,
        });
      },
      onError: (err) => {
        notify(err?.message);
      },
    });
  };

  return (
    <DefaultLayout>
      <BackPanel isSmall>
        <Heading>Edit Case Study</Heading>
        <Button
          className="w-full text-capitalize mt-40"
          xsradii
          onClick={handleDeleteCaseStudy}
        >
          {deleteLoading ? 'Loading' : 'Delete Case Study'}
        </Button>

        <FormContent>
          <FormBlock className={'spacer-t'}>
            <FormBlockItem label={'Project Title'}>
              <FormInput
                isRequired
                type="text"
                register={register}
                name="title"
                defaultValue={caseStudy?.title || ''}
                error={errors.title ? `Project title is required` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>
            <div className="form__input-block">
              <div className="form-v2__label">
                Thumbnail Image (Recommended size 720x576px)
              </div>
              <div className="form-v2__input-wrapper">
                <UploadField
                  onSetImage={(file) => {
                    setThumbImage(file);
                  }}
                  maxFileSize={5e6}
                  defaultImage={caseStudy?.thumbnail}
                />
                <div className="form__clear form__hidden">
                  <i className="fas fa-times" />
                </div>
              </div>
            </div>
            <FormBlockItem label={'Add A Brief Description'}>
              <FormInput
                isRequired
                type="wysiwyg"
                name="description"
                error={
                  submitted && (!description || description?.length === 0)
                    ? `Please add a description`
                    : null
                }
                defaultValue={description}
                showErrorText
                maxLength="1000"
                clearErrors={clearErrors}
                setValue={setDescription}
                fieldClassName={`${
                  submitted && (!description || description?.length === 0)
                    ? 'error-state '
                    : ''
                }`}
              ></FormInput>
            </FormBlockItem>
            <div className="form-v2__input-block">
              <div className="field__label text__gray">Images/Videos</div>
              <div className="form__input-wrapper">
                <EditUpload
                  content={originalContent}
                  setOriginalContent={setOriginalContent}
                  setUpdatedContent={setUpdatedContent}
                  updatedContent={updatedContent}
                />
              </div>
            </div>
            <FormBlockItem label={'Youtube/Vimeo Link'}>
              <FormInput
                type="text"
                register={register}
                name="video_url"
                defaultValue={caseStudy?.video_url}
                clearErrors={clearErrors}
              ></FormInput>
              {watchFields.video_url && (
                <div className="mt-20 up-container">
                  <div
                    className="embed-responsive embed-responsive-16by9"
                    style={{ borderRadius: '12px' }}
                  >
                    <CloseButton
                      onClick={() => {
                        setValue('video_url', '');
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </CloseButton>
                    <ReactPlayer
                      url={watchFields.video_url}
                      width={'100%'}
                      height={'100%'}
                    />
                  </div>
                </div>
              )}
            </FormBlockItem>
            <FormBlockItem label={'External URL'}>
              <FormInput
                type="text"
                register={register}
                name="external_url"
                defaultValue={caseStudy?.external_url}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormCTA spaceT extraSpace>
              <Button
                type="submit"
                className="w-full text-capitalize"
                size="xxl"
                disabled={!!updateLoading}
                onClick={(e) => {
                  setSubmitted(true);
                  handleSubmit(handleEditCaseStudy)(e);
                }}
              >
                {updateLoading ? 'Loading...' : 'Save'}
              </Button>
            </FormCTA>
          </FormBlock>
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
};

export default EditCaseSudy;

const CloseButton = styled.div`
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  border-radius: 100px;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
