import React, { useEffect, useMemo, useRef, useState } from 'react';
import languages from 'language-list';
import { StepCounter } from '../../../components';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  BackPanel,
  Button,
  FormBlock,
  FormBlockItem,
  FormContent,
  FormCTA,
  FormFieldUpload,
  FormInput,
  Heading,
  Text,
} from '../../../snippets';
import useGroups from '../../../hooks/useGroups';
import useProfile from '../../../hooks/useProfile';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { userVar } from '../../../../graphql/config';
import { SortingPrioritize } from '../../../../helpers/array';
import {
  saveUserLocalstorage,
  arrToDropdownSelectionV2,
} from '../../../../utils/helpers';
import { useHistory } from 'react-router-dom';
import {
  TYPE_ETHNICITIES,
  TYPE_HEAR_US,
  TYPE_NOTICE_PERIOD_UNIT,
  TYPE_PAYROLL_STATUS,
  TYPE_RIGHT_TO_WORK,
  TYPE_START_DATE,
  TYPE_WORK_LOCATION_FOR_CONSULTANT,
} from '../../../placeholders/consultant/types';
import {
  ATTACH_INDUSTRY,
  ATTACH_POSITION,
  GET_POSITIONS,
} from '../../../../graphql';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import CustomMultiselect from '../../../components/CustomMultiselect';
import { convertToHTML } from 'draft-convert';

export default function ConsultantCompleteProfilePage() {
  const { groupname } = useGroups();
  const multiselectRoleRef = useRef(null);
  const multiselectLangRef = useRef(null);
  const multiselectPositionRef = useRef(null);
  const [selectedJobLevels, setSelectedJobLevels] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [langs, setLangs] = useState([]);
  const [bio, setBio] = useState('');
  const [updateIndustries] = useMutation(ATTACH_INDUSTRY);
  const history = useHistory();
  const maxLength = 1000;

  const {
    checkProfileCompleted,
    myProfile,
    loadingProfile: loading,
    dataImg,
    setDataImageFile,
    setDataImageErrorStatus,
    loadingRoles,
    allJoblevels,
    updateProfile,
    industries,
    loadingIndustries,
  } = useProfile({ actionImageMandatory: false });

  const { register, handleSubmit, watch, errors, clearErrors, setValue } =
    useForm();

  const updateIndustriesRequest = () => {
    return updateIndustries({
      variables: {
        input: { sync: selectedIndustry },
      },
    });
  };

  const handleJobChange = (selectedList, _) =>
    setSelectedJobLevels(selectedList.map((s) => s.key));

  const [
    getPositions,
    { data: { positions: positionsData = [] } = {}, loading: loadingPositions },
  ] = useLazyQuery(GET_POSITIONS, {
    variables: {
      industries: selectedIndustry,
      jobLevels: selectedJobLevels,
    },
    onError: () =>
      toast.error('Could not get positions', {
        toastId: 'closeGetPositionsErr',
      }),
  });

  const handlePositionChange = (selectedList, _) =>
    setSelectedPositions(selectedList.map((s) => s.id));

  const [updatePositions] = useMutation(ATTACH_POSITION);

  const updatePositionsRequest = () => {
    toast.dismiss();
    updatePositions({
      variables: {
        input: {
          sync: selectedPositions,
        },
      },
    });
  };

  useEffect(() => {
    checkProfileCompleted();
  }, [myProfile, checkProfileCompleted]);

  useEffect(() => {
    if (selectedIndustry && selectedJobLevels?.length > 0) {
      getPositions();
    }
  }, [selectedIndustry, selectedJobLevels, getPositions]);

  const title = useMemo(
    () => (groupname === 'company' ? "You're signed up!" : "You're signed up!"),
    [groupname]
  );

  const editProfile = (data) => {
    toast.dismiss();

    if (dataImg.error) {
      return toast.error('Please select a valid image file.');
    }

    if (bio?.getCurrentContent().getPlainText().length > maxLength) {
      return toast.error(`Bio must be less than ${maxLength} characters`, {
        toastId: 'maxLength',
      });
    }

    updateIndustriesRequest();
    updatePositionsRequest();

    const {
      location,
      payroll_status,
      right_to_work,
      start_date,
      notice_period,
      notice_period_unit,
      ethnicity,
      hear_us,
      hear_us_additional,
      referee_1,
      referee_2,
    } = data;

    const np = `${notice_period} ${notice_period_unit}`;

    return updateProfile({
      variables: {
        input: {
          avatar: dataImg.filepath,
          looking_for: selectedJobLevels,
          bio: convertToHTML(bio?.getCurrentContent()),
          location,
          payroll_status,
          right_to_work,
          start_date,
          language: langs.map((l) => l.key),
          notice_period: np,
          ethnicity,
          hear_us,
          hear_us_additional,
          referee_1,
          referee_2,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        userVar({ ...userVar(), ...updateUser });
        saveUserLocalstorage(userVar());
        toast.success('Profile completed!', { autoClose: 4000 });
        return history.push('/' + groupname + '/edit-profile');
      })
      .catch((e) => console.error(e));
  };

  const langSorts = [36, 38, 177, 56, 46]; // 'English', 'Spanish', 'Chinese', 'Hindi', 'French'
  const watchFields = watch(['hear_us']);

  return (
    <DefaultLayout>
      <BackPanel isSmall>
        <Heading>{title}</Heading>
        <FormContent>
          <StepCounter steps={3} currentAt={3}></StepCounter>
          <div>
            <Heading as="h3" className="text-center">
              Now tell us a little about yourself...
            </Heading>

            <FormBlock className={'spacer-t'}>
              <FormBlockItem
                className="relative"
                label={'Add your photo (or you can leave this for later)'}
              >
                <FormFieldUpload
                  square
                  noOutline
                  center
                  setImg={setDataImageFile}
                  error={dataImg?.error ? 'Please add your photo' : null}
                  showWarnError
                  onError={setDataImageErrorStatus}
                  maxFileSize={8388608}
                  imageRounded={true}
                ></FormFieldUpload>
              </FormBlockItem>

              <FormBlockItem
                label={'Profile Bio (This is not a CV, just a simple overview)'}
              >
                <FormInput
                  isRequired
                  type="wysiwyg"
                  maxLength={maxLength}
                  error={
                    bio.length === 0 ? `Please add your profile bio` : null
                  }
                  setValue={(value) => {
                    setBio(value);
                  }}
                  showErrorText
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Interested In'}>
                {loadingIndustries && <span>Loading...</span>}
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="industry"
                  error={errors.industry ? `Please select your interest` : null}
                  options={arrToDropdownSelectionV2(industries) ?? []}
                  selectSetting={{
                    onSelect: (value) => {
                      setSelectedIndustry(value);
                    },
                  }}
                  placeholder="Interested In"
                  showErrorText
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Preferred Levels of Roles'}>
                {loadingRoles && <span>Loading...</span>}
                {allJoblevels && (
                  <CustomMultiselect
                    options={allJoblevels}
                    onSelect={handleJobChange}
                    onRemove={handleJobChange}
                    displayValue="name"
                    closeOnSelect={false}
                    avoidHighlightFirstOption
                    placeholder="Level of Role"
                    selectionLimit={2}
                    ref={multiselectRoleRef}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Position Sought (optional)'}>
                {loadingPositions && <span>Loading positions...</span>}
                {positionsData && (
                  <CustomMultiselect
                    options={positionsData}
                    onSelect={handlePositionChange}
                    onRemove={handlePositionChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Position"
                    selectionLimit={5}
                    ref={multiselectPositionRef}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'How Would You Like To Work?'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="location"
                  error={
                    errors.location
                      ? `Please select your preferred work location`
                      : null
                  }
                  options={TYPE_WORK_LOCATION_FOR_CONSULTANT}
                  placeholder="Preferred work location"
                  showErrorText
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem
                label={
                  'Do you require going on the payroll or are you a limited company?'
                }
              >
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="payroll_status"
                  error={
                    errors.payroll_status
                      ? `Please select your payroll status`
                      : null
                  }
                  options={TYPE_PAYROLL_STATUS}
                  placeholder="Payroll status"
                  showErrorText
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Do You Require A Visa To Work In The UK?'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="right_to_work"
                  error={
                    errors.right_to_work
                      ? `Please select your right to work`
                      : null
                  }
                  options={TYPE_RIGHT_TO_WORK}
                  placeholder="Right to work"
                  showErrorText
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem
                label={'When Are You Looking To Start Your New Job?'}
              >
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="start_date"
                  error={
                    errors.start_date ? `Please select your availability` : null
                  }
                  options={TYPE_START_DATE}
                  placeholder="Start date"
                  showErrorText
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Which Languages Do You Work In?'}>
                <CustomMultiselect
                  options={languages()
                    .getData()
                    .map((elm, index) => ({
                      id: index,
                      key: elm.language,
                      name: elm.language,
                    }))
                    .sort(SortingPrioritize(langSorts))}
                  onSelect={(a) => {
                    setLangs(a);
                  }}
                  onRemove={(a) => {
                    setLangs(a);
                  }}
                  displayValue="name"
                  closeOnSelect={true}
                  avoidHighlightFirstOption
                  placeholder="Languages"
                  selectionLimit={10}
                  selectedValues={langs}
                  ref={multiselectLangRef}
                />
              </FormBlockItem>

              <FormBlock isInline label={'What is your notice period?'}>
                <FormBlockItem>
                  <FormInput
                    isRequired
                    type="select"
                    register={register}
                    name="notice_period"
                    error={
                      errors.notice_period
                        ? `Please select your notice period`
                        : null
                    }
                    options={Array(31)
                      .fill()
                      .map((a, i) => ({
                        value: i + 1,
                      }))}
                    placeholder="Notice period"
                    showErrorText
                    clearErrors={clearErrors}
                  ></FormInput>
                </FormBlockItem>

                <FormBlockItem>
                  <FormInput
                    isRequired
                    type="select"
                    register={register}
                    name="notice_period_unit"
                    error={
                      errors.notice_period_unit
                        ? `Please select your notice period unit`
                        : null
                    }
                    options={TYPE_NOTICE_PERIOD_UNIT}
                    placeholder="Notice period unit"
                    showErrorText
                    clearErrors={clearErrors}
                  ></FormInput>
                </FormBlockItem>
              </FormBlock>

              <FormBlock isInline>
                <FormBlockItem label="Ethnicity (optional)">
                  <FormInput
                    type="select"
                    register={register}
                    name="ethnicity"
                    options={TYPE_ETHNICITIES.map((v) => ({
                      value: v,
                      label: v,
                    }))}
                    placeholder="Ethnicity"
                  ></FormInput>
                  <p className="text-sm font-weight-bold font-italic mt-3 mb-2">
                    Why are you asking me these questions?
                  </p>
                  <p className="text-sm font-italic mb-2">
                    We believe that every candidate should feel included in the
                    workplace. Liberty Hive is dedicated to building a platform
                    that helps employers hire in a more inclusive way.
                  </p>
                  <p className="text-sm font-italic">
                    To help us do this, we’d like to know more about you.
                    Sharing this data with us is optional and is confidential
                    and does not get shared with other parties. You can learn
                    more about how we use your personal data in our privacy
                    policy.
                  </p>
                </FormBlockItem>
              </FormBlock>

              <FormBlockItem className="spacer-t" label={'References'}>
                <Text
                  className="mb-2"
                  style={{
                    color: '#AAAAAA',
                  }}
                >
                  Reference 1 — Name, Company and Position
                </Text>
                <FormInput
                  type="text"
                  register={register}
                  name="referee_1"
                  clearErrors={clearErrors}
                ></FormInput>
                <Text
                  className="mt-4 mb-2"
                  style={{
                    color: '#AAAAAA',
                  }}
                >
                  Reference 2 — Name, Company and Position
                </Text>
                <FormInput
                  type="text"
                  register={register}
                  name="referee_2"
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'How did you hear about us?'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="hear_us"
                  error={
                    errors.hear_us
                      ? `Please select where did you hear about us`
                      : null
                  }
                  options={TYPE_HEAR_US}
                  placeholder="How did you hear about us?"
                  showErrorText
                  selectSetting={{
                    onSelect: (value) => {
                      setValue('hear_us', value);
                    },
                  }}
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              {'hear_us' in watchFields &&
                (watchFields.hear_us === 'recommendation' ||
                  watchFields.hear_us === 'other') && (
                  <FormBlockItem label={'Please specify (optional)'}>
                    <FormInput
                      type="text"
                      register={register}
                      name="hear_us_additional"
                      clearErrors={clearErrors}
                    ></FormInput>
                  </FormBlockItem>
                )}

              <FormCTA spaceT extraSpace>
                <Button
                  type="submit"
                  className="w-full"
                  size="xxl"
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(editProfile)(e);
                  }}
                >
                  {loading ? 'Loading...' : 'Save'}
                </Button>
              </FormCTA>
            </FormBlock>
          </div>
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
}
