import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromHTML } from 'draft-convert';

export default function Wysiwyg({
  name,
  defaultValue = '',
  onChange,
  maxLength = 1000,
}) {
  const [content, setContent] = useState(EditorState.createEmpty());
  const [contentLength, setContentLength] = useState(
    content?.getCurrentContent().getPlainText('\u0001').length
  );

  const handleOnChange = (editorState) => {
    setContent(editorState);
    setContentLength(
      editorState?.getCurrentContent().getPlainText('\u0001').length
    );
    onChange(editorState);
  };

  useEffect(() => {
    if (typeof defaultValue === 'string' && defaultValue.length > 0) {
      const value = EditorState.createWithContent(
        convertFromHTML(defaultValue)
      );
      const valueLength = value.length;

      if (!content || content?.getCurrentContent()?.hasText() === false) {
        setContent(value);
        setContentLength(valueLength);
        onChange(value);
      }
    } else if (typeof defaultValue === 'object') {
      setContent(defaultValue);
      setContentLength(
        defaultValue?.getCurrentContent()?.getPlainText('\u0001').length
      );
    }
  }, [defaultValue, content]);

  return (
    <>
      <Editor
        name={name}
        editorState={content}
        onEditorStateChange={handleOnChange}
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
        }}
        handlePastedText={() => false}
        stripPastedStyles={true}
      />
      {maxLength !== 0 && (
        <div className="field-add-ons">
          <div className="help-text">{<span>{contentLength}</span>} / 1000</div>
        </div>
      )}
    </>
  );
}
