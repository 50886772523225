import React, { useCallback, useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
} from '../../snippets';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_ALL_JOB_LEVELS,
  GET_POSITIONS,
  UPDATE_PROFILE,
  ATTACH_POSITION,
} from '../../../graphql';
import { userVar } from '../../../graphql/config';
import {
  TYPE_ETHNICITIES,
  TYPE_NOTICE_PERIOD_UNIT,
  TYPE_PAYROLL_STATUS,
  TYPE_PRONOUNS,
  TYPE_RIGHT_TO_WORK,
  TYPE_START_DATE,
  TYPE_WORK_LOCATION_FOR_CONSULTANT,
} from '../../placeholders/consultant/types';
import languages from 'language-list';
import { SectionProfile } from '../../components';
import {
  getLoginType,
  getUser,
  saveUserLocalstorage,
} from '../../../utils/helpers';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CustomMultiselect from '../../components/CustomMultiselect';
import { SortingPrioritize } from '../../../helpers/array';
import { LINK_GROUP } from '../../../utils/links';
import useAuth from '../../hooks/useAuth';
import { convertToHTML } from 'draft-convert';

export default function PersonalInformationEditPage() {
  const { register, handleSubmit, errors, clearErrors, control, setValue } =
    useForm();
  const history = useHistory();
  const multiselectRoleRef = useRef(null);
  const multiselectLangRef = useRef(null);
  const multiselectPositionRef = useRef(null);

  const {
    bio: bioOri,
    address_1,
    address_2,
    avatar,
    city,
    notice_period,
    country,
    state,
    zip,
    phone,
    payroll_status,
    location,
    jobtitle,
    pronouns,
    language,
    start_date,
    right_to_work,
    ethnicity,
  } = userVar();

  const [bio, setBio] = useState(bioOri);

  const noticePeriods = notice_period?.split(' ');

  const { authMe: myData } = useAuth();

  const [img, setImg] = useState(null);

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);

  const [phoneNo, setPhoneNo] = useState('');

  const [selectedJobLevels, setSelectedJobLevels] = useState([]);

  const [selectedPositions, setSelectedPositions] = useState([]);

  const [langs, setLangs] = useState([]);
  const maxLength = 1000;

  const { data: { allJoblevels } = {}, loading: loadingRoles } =
    useQuery(GET_ALL_JOB_LEVELS);

  const [
    getPositions,
    { data: { positions: positionsData = [] } = {}, loading: loadingPositions },
  ] = useLazyQuery(GET_POSITIONS, {
    variables: {
      industries: myData?.industries?.map((industry) => industry.id),
      jobLevels: selectedJobLevels,
    },
    onError: () =>
      toast.error('Could not get positions', {
        toastId: 'closeGetPositionsErr',
      }),
  });

  const [updatePositions] = useMutation(ATTACH_POSITION);

  const updatePositionsRequest = () => {
    toast.dismiss();
    updatePositions({
      variables: {
        input: {
          sync: selectedPositions,
        },
      },
    });
  };

  useEffect(() => {
    setPhoneNo(phone);
  }, [phone, setValue]);

  useEffect(() => {
    if (avatar) {
      setImg(avatar);
    }
  }, [avatar, setImg]);

  useEffect(() => {
    if (myData?.looking_for?.length > 0) {
      setSelectedJobLevels(myData?.looking_for);
    }

    if (myData?.positions?.length > 0) {
      setSelectedPositions(myData?.positions?.map((position) => position.id));
    }
  }, [myData]);

  useEffect(() => {
    if (myData?.industries?.length > 0 && selectedJobLevels?.length > 0) {
      getPositions();
    }
  }, [selectedJobLevels, myData, getPositions]);

  useEffect(() => {
    if (language) {
      const _selected = languages()
        .getData()
        ?.filter((l) => {
          if (language.find((f) => l.language === f)) {
            return true;
          }
          return false;
        });
      setLangs(
        _selected.map((a) => {
          return {
            key: a.language,
            name: a.language,
          };
        })
      );
    }
  }, [language]);

  const preselectedJobLevels = useCallback(
    () =>
      allJoblevels?.filter((level) => selectedJobLevels.includes(level.key)),
    [allJoblevels, selectedJobLevels]
  );

  const handleJobChange = (selectedList, _) => {
    setSelectedJobLevels(selectedList.map((s) => s.key));
    setSelectedPositions([]);
  };

  const preselectedPositions = useCallback(
    () =>
      positionsData?.filter((position) =>
        selectedPositions?.includes(position.id)
      ),
    [positionsData, selectedPositions]
  );

  const handlePositionChange = (selectedList, _) =>
    setSelectedPositions(selectedList.map((s) => s.id));

  const editProfile = async (data) => {
    toast.dismiss();

    if (bio?.getCurrentContent().getPlainText().length > maxLength) {
      return toast.error(`Bio must be less than ${maxLength} characters`, {
        toastId: 'maxLength',
      });
    }

    const np = `${data.notice_period} ${data.notice_period_unit}`;

    delete data.notice_period_unit;

    let res = {
      ...data,
      phone: phoneNo,
      looking_for: selectedJobLevels,
      notice_period: np,
      language: langs.map((l) => l.key),
      bio: convertToHTML(bio?.getCurrentContent()),
    };
    // if (!img) {
    //   res = {
    //     ...res,
    //     avatar: avatar,
    //   };
    // }

    if (img && img !== avatar) {
      res = {
        ...res,
        avatar: img,
      };
    }
    try {
      updatePositionsRequest();
      const {
        data: { updateUser },
      } = await updateProfile({
        variables: {
          input: res,
        },
      });
      const updatedUserObj = { ...getUser(), ...updateUser };
      userVar(updatedUserObj);
      saveUserLocalstorage(updatedUserObj);
      return history.push(`/${getLoginType()}/personal-information`);
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!', { toastId: 'resErr' });
    }
  };

  const langSorts = [36, 38, 177, 56, 46]; // 'English', 'Spanish', 'Chinese', 'Hindi', 'French'

  return (
    <DefaultLayout showSubmenu>
      <SectionProfile
        headline="Edit Personal Information"
        isEditor
        setImg={setImg}
        editDefaultImage={avatar}
        imageRounded={getLoginType() === LINK_GROUP.CANDIDATE}
      >
        <FormBlock>
          <FormBlockItem label={'About Me'}>
            <FormInput
              isRequired
              type="wysiwyg"
              name="bio"
              error={bio?.length === 0 ? `Add bio` : null}
              maxLength={maxLength}
              defaultValue={bio}
              clearErrors={clearErrors}
              setValue={setBio}
              fieldClassName={`${bio?.length === 0 ? 'error-state ' : ''}`}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'What Are Your Pronouns? (optional)'}>
            <FormInput
              type="select"
              register={register}
              name="pronouns"
              defaultValue={pronouns}
              options={[
                { value: '', label: 'What Are Your Pronouns?' },
                ...TYPE_PRONOUNS,
              ]}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Latest Job Title'}>
            <FormInput
              type="text"
              register={register}
              name="jobtitle"
              defaultValue={jobtitle}
              error={
                errors.jobtitle ? `Please enter your last job title` : null
              }
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Address Line 1'}>
            <FormInput
              type="text"
              register={register}
              name="address_1"
              defaultValue={address_1}
              isRequired
              error={errors.address_1 ? `Please enter an address` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Address Line 2'}>
            <FormInput
              type="text"
              register={register}
              name="address_2"
              defaultValue={address_2}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'City'}>
            <FormInput
              type="text"
              register={register}
              name="city"
              defaultValue={city}
              isRequired
              error={errors.city ? `Please enter city` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'County'}>
            <FormInput
              type="text"
              register={register}
              name="state"
              defaultValue={state}
              isRequired
              error={errors.state ? `Please enter an county` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Country'}>
            <FormInput
              type="text"
              register={register}
              name="country"
              defaultValue={country}
              isRequired
              error={errors.country ? `Please enter country` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Postcode / Zip Code'}>
            <FormInput
              type="text"
              register={register}
              name="zip"
              defaultValue={zip}
              isRequired
              error={errors.zip ? `Please enter postcode / zip code` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Phone Number'}>
            <FormInput
              type="phone"
              register={register}
              name="phone_1"
              defaultValue={phone}
              setPhoneNo={setPhoneNo}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Preferred Levels Of Roles'}>
            {loadingRoles && <span>Loading roles...</span>}
            {allJoblevels && (
              <CustomMultiselect
                options={allJoblevels}
                onSelect={handleJobChange}
                onRemove={handleJobChange}
                displayValue="name"
                closeOnSelect={true}
                avoidHighlightFirstOption
                placeholder="Level of Role"
                selectionLimit={3}
                selectedValues={preselectedJobLevels()}
                ref={multiselectRoleRef}
              />
            )}
          </FormBlockItem>

          <FormBlockItem label={'Position Sought'}>
            {loadingPositions && <span>Loading positions...</span>}
            {positionsData && (
              <CustomMultiselect
                options={positionsData}
                onSelect={handlePositionChange}
                onRemove={handlePositionChange}
                displayValue="name"
                closeOnSelect={true}
                avoidHighlightFirstOption
                placeholder="Position"
                selectionLimit={5}
                selectedValues={preselectedPositions()}
                ref={multiselectPositionRef}
              />
            )}
          </FormBlockItem>

          <FormBlockItem
            label={
              'Do you require going on the payroll or are you a limited company?'
            }
          >
            <FormInput
              isRequired
              type="select"
              register={register}
              name="payroll_status"
              error={
                errors.payroll_status
                  ? `Please select your payroll status`
                  : null
              }
              defaultValue={payroll_status ?? '1'}
              options={TYPE_PAYROLL_STATUS}
              placeholder="Payroll status"
              showErrorText
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Do You Require A Visa To Work In The UK?'}>
            <FormInput
              isRequired
              type="select"
              register={register}
              name="right_to_work"
              error={
                errors.right_to_work ? `Please select your right to work` : null
              }
              defaultValue={right_to_work}
              options={TYPE_RIGHT_TO_WORK}
              placeholder="Right to work"
              showErrorText
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'How Would You Like To Work?'}>
            <FormInput
              isRequired
              type="select"
              register={register}
              name="location"
              error={
                errors.location
                  ? `Please select your preferred work location`
                  : null
              }
              defaultValue={location}
              options={TYPE_WORK_LOCATION_FOR_CONSULTANT}
              placeholder="Preferred work location"
              showErrorText
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Which Languages Do You Work In?'}>
            <CustomMultiselect
              options={languages()
                .getData()
                .map((elm, index) => ({
                  id: index,
                  key: elm.language,
                  name: elm.language,
                }))
                .sort(SortingPrioritize(langSorts))}
              onSelect={(a) => {
                setLangs(a);
              }}
              onRemove={(a) => {
                setLangs(a);
              }}
              displayValue="name"
              closeOnSelect={true}
              avoidHighlightFirstOption
              placeholder="Languages"
              selectionLimit={10}
              selectedValues={langs}
              ref={multiselectLangRef}
            />
          </FormBlockItem>

          <FormBlockItem label={'When Are You Looking To Start Your New Job?'}>
            <FormInput
              isRequired
              type="select"
              register={register}
              name="start_date"
              error={
                errors.start_date ? `Please select your availability` : null
              }
              defaultValue={start_date}
              options={TYPE_START_DATE}
              placeholder="Start date"
              showErrorText
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>
        </FormBlock>

        <FormBlock isInline label={'What is your notice period?'}>
          <FormBlockItem>
            <FormInput
              isRequired
              type="select"
              register={register}
              name="notice_period"
              error={
                errors.notice_period ? `Please select your notice period` : null
              }
              defaultValue={parseInt(noticePeriods ? noticePeriods[0] : null)}
              options={Array(31)
                .fill()
                .map((a, i) => ({
                  value: i + 1,
                }))}
              placeholder="Notice period"
              showErrorText
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem>
            <FormInput
              isRequired
              type="select"
              register={register}
              name="notice_period_unit"
              error={
                errors.notice_period_unit
                  ? `Please select your notice period unit`
                  : null
              }
              defaultValue={noticePeriods ? noticePeriods[1] : null}
              options={TYPE_NOTICE_PERIOD_UNIT}
              placeholder="Notice period unit"
              showErrorText
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>
        </FormBlock>

        <FormBlock isInline>
          <FormBlockItem label="Ethnicity (optional)">
            <FormInput
              type="select"
              register={register}
              name="ethnicity"
              defaultValue={ethnicity}
              options={TYPE_ETHNICITIES.map((v) => ({ value: v, label: v }))}
              placeholder="Ethnicity"
            ></FormInput>
            <p className="text-sm font-weight-bold font-italic mt-3 mb-2">
              Why are you asking me these questions?
            </p>
            <p className="text-sm font-italic mb-2">
              We believe that every candidate should feel included in the
              workplace. Liberty Hive is dedicated to building a platform that
              helps employers hire in a more inclusive way.
            </p>
            <p className="text-sm font-italic">
              To help us do this, we’d like to know more about you. Sharing this
              data with us is optional and is confidential and does not get
              shared with other parties. You can learn more about how we use
              your personal data in our privacy policy.
            </p>
          </FormBlockItem>
        </FormBlock>

        <FormCTA spaceT extraSpace>
          <Button
            type="submit"
            className="w-full"
            size="xxl"
            disabled={loading}
            onClick={handleSubmit(editProfile)}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
        </FormCTA>
      </SectionProfile>
    </DefaultLayout>
  );
}
