import React, { useEffect, useState, Fragment } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
} from '../../snippets';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PROFILE } from '../../../graphql';
import { userVar } from '../../../graphql/config';
import { SectionProfile } from '../../components';
import { getUser, saveUserLocalstorage } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { inputStyle } from '../../../utils/helpers';
import { IconPlusSmall } from '../../../components/Icons';
import { convertToHTML } from 'draft-convert';

const MAX_PHONE = 4;

export default function PersonalInformationEditPage() {
  const { register, handleSubmit, watch, errors, clearErrors } = useForm();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const history = useHistory();
  const {
    bio: userBio,
    address_1,
    address_2,
    avatar,
    city,
    country,
    state,
    zip,
    email,
    agency_name,
    agency_phone,
  } = userVar();

  const [img, setImg] = useState(null);
  const [bio, setBio] = useState(userBio);
  const maxLength = 1000;

  useEffect(() => {
    if (agency_phone) {
      setPhoneNumbers(
        agency_phone?.map((p) => {
          return p?.attributes?.phone;
        })
      );
    }
  }, [agency_phone]);

  function handleRemovePhone(idxAt) {
    setPhoneNumbers((prev) => {
      const _curr = prev.slice(0);
      _curr.splice(idxAt, 1);
      return _curr;
    });
  }

  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, '']);
  };

  useEffect(() => {
    if (avatar) {
      setImg(avatar);
    }
  }, [avatar, setImg]);

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);

  const editProfile = (data) => {
    toast.dismiss();
    if (!img && !avatar) {
      return toast.error('Please select a valid image file.');
    }

    if (bio?.getCurrentContent().getPlainText().length > maxLength) {
      return toast.error(`Bio must be less than ${maxLength} characters`, {
        toastId: 'maxLength',
      });
    }

    let res = {
      ...data,
      bio: convertToHTML(bio?.getCurrentContent()),
      agency_phone: phoneNumbers
        .map((p) => {
          if (p.includes('+')) {
            return `${p}`;
          } else {
            return `+${p}`;
          }
        })
        .join(','),
    };

    if (img !== null && img !== avatar) {
      res = {
        ...res,
        avatar: img,
      };
    }

    return updateProfile({
      variables: {
        input: res,
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        return history.push('/agency/account-information');
      })
      .catch((e) => {
        toast.error('Something went wrong!', { toastId: 'resErr' });
      });
  };

  return (
    <DefaultLayout showSubmenu>
      <SectionProfile
        headline="EDIT ACCOUNT INFORMATION"
        profilePictureHeading={'Profile Picture'}
        editDefaultImage={avatar}
        setImg={setImg}
        isEditor
      >
        <FormBlock>
          <FormBlockItem label={'About Us'}>
            <FormInput
              isRequired
              type="wysiwyg"
              name="bio"
              error={bio?.length === 0 ? `Add bio` : null}
              defaultValue={bio}
              maxLength={maxLength}
              clearErrors={clearErrors}
              setValue={setBio}
              fieldClassName={`${bio?.length === 0 ? 'error-state ' : ''}`}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Agency Name'}>
            <FormInput
              type="text"
              register={register}
              name="agency_name"
              defaultValue={agency_name}
              error={
                errors.agency_name ? `Please enter your agency name` : null
              }
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Address Line 1'}>
            <FormInput
              type="text"
              register={register}
              name="address_1"
              defaultValue={address_1}
              isRequired
              error={errors.address_1 ? `Please enter an address` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Address Line 2'}>
            <FormInput
              type="text"
              register={register}
              name="address_2"
              defaultValue={address_2}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'City'}>
            <FormInput
              type="text"
              register={register}
              name="city"
              defaultValue={city}
              isRequired
              error={errors.city ? `Please enter city` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'County'}>
            <FormInput
              type="text"
              register={register}
              name="state"
              defaultValue={state}
              isRequired
              error={errors.state ? `Please enter an county` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Country'}>
            <FormInput
              type="text"
              register={register}
              name="country"
              defaultValue={country}
              isRequired
              error={errors.country ? `Please enter country` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Postcode / Zip Code'}>
            <FormInput
              type="text"
              register={register}
              name="zip"
              defaultValue={zip}
              isRequired
              error={errors.zip ? `Please enter postcode / zip code` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Email'}>
            <FormInput
              type="text"
              register={register}
              name="email"
              defaultValue={email}
              isRequired
              error={errors.email ? `Please enter a valid email address` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Phone Number'}>
            {[...phoneNumbers].map((a, i) => {
              return (
                <Fragment key={i + a}>
                  <div className={`${i > 0 ? 'mt-16' : ''} row`}>
                    <div className="col">
                      <PhoneInput
                        value={a}
                        country="gb"
                        autoFormat
                        countryCodeEditable={false}
                        inputProps={{
                          name: `phone[${i}]`,
                          required: true,
                          autoFocus: false,
                        }}
                        inputStyle={inputStyle}
                        inputClass={'form-v2__input with-important '}
                        containerClass="form-v2__input-wrapper"
                        buttonClass="field-v2__phone-button"
                        onChange={(phone) => {
                          setPhoneNumbers((prev) => {
                            prev[i] = phone;
                            return prev;
                          });
                        }}
                        dropdownStyle={{
                          background: '#fff',
                          color: 'black',
                          borderBottomLeftRadius: '12px',
                          borderBottomRightRadius: '12px',
                        }}
                        placeholder="44 116 456 5566"
                        // isValid={(value) => validatePhone(value)}
                      />
                    </div>
                    {i > 0 && (
                      <div className="col-auto pl-0">
                        <button
                          type="button"
                          className="btn btn-secondary fs-28x leading-none h-full"
                          onClick={() => handleRemovePhone(i)}
                        >
                          -
                        </button>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}

            {phoneNumbers.length < MAX_PHONE && (
              <button
                className="btn__add-more-v2"
                onClick={handleAddPhoneNumber}
                type="button"
              >
                <div>
                  <IconPlusSmall />
                </div>
                <div className="text__white txt__fw-400 txt__fs-14">
                  Add Another Phone Number
                </div>
              </button>
            )}
          </FormBlockItem>
        </FormBlock>

        <FormCTA spaceT extraSpace>
          <Button
            type="submit"
            className="w-full text-capitalize"
            size="xxl"
            disabled={loading}
            onClick={handleSubmit(editProfile)}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
        </FormCTA>
      </SectionProfile>
    </DefaultLayout>
  );
}
