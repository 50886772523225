import React from 'react';
import { BackPanel, Heading, Button, Link, Text } from '../../snippets';
import useGroups from '../../hooks/useGroups';
import { IMG_AGENCY_PLACEHOLDER } from '../../../utils/helpers';

export default function GridCaseStudies({
  heading = 'Case Studies',
  viewMore = null,
  buttonCopy = 'View More',
  data,
  id = '',
  showMobileButton = false,
  titleOnly = false,
  headerButtonCopy = null,
  headerButtonUrl = null,
  showViewMore = false,
}) {
  const { groupname } = useGroups();
  return (
    <BackPanel id={id}>
      <div className="gridjobs-header">
        <Heading>{heading}</Heading>
        {headerButtonUrl && groupname === 'agency' && (
          <div className="app-hide-on-mobile">
            <Button
              as="a"
              href={headerButtonUrl}
              className="w-full app-btn--text-xs"
              xsradii
              style={{ width: '224px' }}
            >
              {headerButtonCopy}
            </Button>
          </div>
        )}
      </div>
      {showMobileButton && (
        <div className="app-hide-on-desktop mt-20">
          <Button
            as="a"
            href={headerButtonUrl}
            className="w-full app-btn--text-xs"
            xsradii
          >
            {headerButtonCopy}
          </Button>
        </div>
      )}
      <div className="gridcasestudies-grid">
        {data.map((d, i) => {
          return (
            <div className="gridcasestudies-grid--item" key={i}>
              <div>
                <Link to={`/${groupname}/case-study/${d.id}`}>
                  <div
                    className={`gridcasestudies-grid--image`}
                    style={{ borderRadius: titleOnly ? '6px' : '1px' }}
                  >
                    <img src={d.img || IMG_AGENCY_PLACEHOLDER} alt="" />
                  </div>
                </Link>
                <div className="grid-item--divider"></div>
                {!titleOnly && (
                  <>
                    <div className="grid-item--title">
                      {d.headline.length > 25
                        ? `${d.headline.substring(0, 25)}...`
                        : d.headline}
                    </div>
                    {/* <div className="grid-item--description">
                      <Text
                        as="div"
                        dangerouslySetInnerHTML={{
                          __html: d.info.substring(0, 25) + '...',
                        }}
                      ></Text>
                    </div> */}
                  </>
                )}
                {titleOnly && (
                  <div className="grid-item--title-only">
                    {d.headline.length > 25
                      ? `${d.headline.substring(0, 25)}...`
                      : d.headline}
                  </div>
                )}
              </div>
              {groupname === 'agency' && !titleOnly && (
                <Button
                  as="link"
                  size="xl"
                  to={`/${groupname}/case-study/edit/${d?.id}`}
                  className="app-btn--text-normal text--capitalize gridcasestudies-grid--btn"
                >
                  Edit
                </Button>
              )}
            </div>
          );
        })}
      </div>
      {viewMore && showViewMore && (
        <div className="d-flex justify-content-center mt-30">
          <Button
            onClick={viewMore}
            className="w-full app-btn--text-xs"
            xsradii
            style={{ width: '224px' }}
          >
            {buttonCopy}
          </Button>
        </div>
      )}
    </BackPanel>
  );
}
