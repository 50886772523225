import React, { useContext, useEffect, useState } from 'react';
import { SectionProfile } from '../../../components';
import { AppContext } from '../../../../Store';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import useGroups from '../../../hooks/useGroups';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
} from '../../../snippets';
import { userVar } from '../../../../graphql/config';
import { getUser, saveUserLocalstorage } from '../../../../utils/helpers';
import DefaultLayout from '../../../layouts/defaultLayout';
import useEmail from '../../../hooks/useEmail';
import useProfile from '../../../hooks/useProfile';
import { convertToHTML } from 'draft-convert';

export default function ProfileEditPage() {
  const [state] = useContext(AppContext);
  const { register, watch, handleSubmit, errors, clearErrors, setError } =
    useForm();
  const { groupname } = useGroups();
  const [img, setImg] = useState(null);
  const [phone, setPhoneNo] = useState(state?.activeCompany?.user?.phone);
  const [bio, setBio] = useState(state?.activeCompany?.user?.bio);
  const [description, setDescription] = useState(
    state?.activeCompany?.user?.description
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const avatar = state?.activeCompany?.user?.avatar;
  const maxLength = 1000;

  const { updateProfile, loadingProfile: loading } = useProfile();

  const { handleCheckEmail, setAfterOnCompletedFN, setOnErrorFN } = useEmail();

  useEffect(() => {
    if (state?.activeCompany?.user) {
      setImg(state?.activeCompany?.user?.avatar);
    }
  }, [state]);

  const editProfile = async (data) => {
    toast.dismiss();

    if (!img && !avatar) {
      setSubmitLoading(false);
      return toast.error('Please select a valid image file.');
    }

    if (
      bio?.getCurrentContent().getPlainText().length > maxLength ||
      description?.getCurrentContent().getPlainText().length > maxLength
    ) {
      setSubmitLoading(false);
      return toast.error(
        `Bio and Description must be less than ${maxLength} characters`,
        {
          toastId: 'maxLength',
        }
      );
    }

    let res = {
      ...data,
      company_id: state?.activeCompany?.team_id,
      bio: convertToHTML(bio?.getCurrentContent()),
      description: convertToHTML(description?.getCurrentContent()),
    };

    delete data.avatar;
    if (img !== null && img !== avatar) {
      res = {
        ...res,
        avatar: img,
      };
    }

    setAfterOnCompletedFN((x) => {
      setSubmitLoading(false);
      updateProfile({
        variables: {
          input: { ...x, phone },
        },
      })
        .then(({ data: { updateUser } }) => {
          const updatedUserObj = { ...getUser(), ...updateUser };
          userVar(updatedUserObj);
          saveUserLocalstorage(updatedUserObj);
          window.location.href = `/${groupname}/profile`;
        })
        .catch((e) => {
          console.error('Unknown Error Code CEP_221:', e);
          toast.error('Something went wrong!', {
            toastId: 'CEP_221',
            autoClose: false,
          });
        });
    });

    setOnErrorFN(() => {
      setSubmitLoading(false);
      setError('email', 'ununique_email');
    });

    handleCheckEmail(res, data.email === state?.activeCompany?.user?.email); // Skip if same email
  };

  return (
    <DefaultLayout showSubmenu>
      <SectionProfile
        headline="Edit Profile"
        isEditor
        setImg={setImg}
        editDefaultImage={state?.activeCompany?.user?.avatar}
      >
        <FormBlock>
          <FormBlockItem label={'Profile Bio'}>
            <FormInput
              isRequired
              type="wysiwyg"
              name="bio"
              error={bio?.length === 0 ? `Add bio` : null}
              defaultValue={bio}
              maxLength={maxLength}
              clearErrors={clearErrors}
              setValue={setBio}
              fieldClassName={`${bio?.length === 0 ? 'error-state ' : ''}`}
            ></FormInput>
          </FormBlockItem>
          <FormBlockItem label={'What Sets You Apart'}>
            <FormInput
              isRequired
              type="wysiwyg"
              name="description"
              error={description?.length === 0 ? `Add description` : null}
              defaultValue={description}
              maxLength={maxLength}
              clearErrors={clearErrors}
              setValue={setDescription}
              fieldClassName={`${
                description?.length === 0 ? 'error-state ' : ''
              }`}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Employer Name'}>
            <FormInput
              type="text"
              register={register}
              name="company"
              isRequired
              error={errors.company ? `Add Employer Name` : null}
              defaultValue={state?.activeCompany?.user?.company}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Address Line 1'}>
            <FormInput
              type="text"
              register={register}
              name="address_1"
              isRequired
              error={errors.address_1 ? `Please enter an address` : null}
              defaultValue={state?.activeCompany?.user?.address_1}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Address Line 2'}>
            <FormInput
              type="text"
              register={register}
              name="address_2"
              defaultValue={state?.activeCompany?.user?.address_2}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'City'}>
            <FormInput
              type="text"
              register={register}
              name="city"
              isRequired
              error={errors.city ? `Please enter city` : null}
              defaultValue={state?.activeCompany?.user?.city}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'County'}>
            <FormInput
              type="text"
              register={register}
              name="state"
              defaultValue={state?.activeCompany?.user?.state}
              isRequired
              error={errors.county ? `Please enter county` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Country'}>
            <FormInput
              type="text"
              register={register}
              name="country"
              defaultValue={state?.activeCompany?.user?.country}
              isRequired
              error={errors.country ? `Please enter country` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Postcode / Zip Code'}>
            <FormInput
              type="text"
              register={register}
              name="zip"
              defaultValue={state?.activeCompany?.user?.zip}
              isRequired
              error={errors.zip ? `Please enter postcode / zip code` : null}
              clearErrors={clearErrors}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Phone Number'}>
            <FormInput
              type="phone"
              register={register}
              defaultValue={state?.activeCompany?.user?.phone}
              name="phone"
              setPhoneNo={setPhoneNo}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Email Address'}>
            <FormInput
              type="email"
              register={register}
              name="email"
              defaultValue={state?.activeCompany?.user?.email}
              clearErrors={clearErrors}
              isRequired
              error={errors.email ? `Please add email address` : null}
            ></FormInput>
          </FormBlockItem>
        </FormBlock>

        <FormCTA spaceT extraSpace>
          <Button
            type="submit"
            className="w-full"
            size="xxl"
            disabled={submitLoading}
            onClick={(e) => {
              setSubmitLoading(true);
              handleSubmit(editProfile)(e);
            }}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
        </FormCTA>
      </SectionProfile>
    </DefaultLayout>
  );
}
